import * as deoHandler from "@bestbuy/deo-handler";

export default function optLssActivation() {
	(function() {
		window.optimizely = window.optimizely || [];
		let optData = window.deoMetaData.fs.data;
		const optExperimentDiv = 'data-optimizely-experiment-key';
		let optActiveTests = document.querySelectorAll('[' + optExperimentDiv + ']');

		if(optActiveTests.length > 0 && optData?.experiments && optData.experiments.length > 0) {
			const targetedExperiments = Array.from(optActiveTests, x => x.getAttribute(optExperimentDiv)).filter(exp => Array.from(optData.experiments, y => y.key).includes(exp));
			if(targetedExperiments.length > 0) {
				const fullStackBatch = new Map();
				fullStackBatch.set("activate", {
					experimentKeys: targetedExperiments
				});
				deoHandler.deoBatch(fullStackBatch);
			}
		}

		let optimizelyListener = {
			type: "addListener",
			filter: { type: "analytics", name: "trackEvent" },
			handler: function(event) {
				let eventName = event?.data?.name ? event.data.name.replace(/\(|\)|\s+\//g, '') : '';
				if(optData?.events && Array.from(optData.events, e => e.key).includes(eventName)) {
					let metrics = event?.data?.metrics ? event.data.metrics : {};
					let tags = event?.data?.tags ? event.data.tags : {};
					let userAttrs = window.deoMetaData.fs.attrs ? Array.from(Object.entries(window.deoMetaData.fs.attrs), attr => { return { [attr[0]]: attr[1] }; }) : [];
					let metricsTags = Object.assign({}, metrics, tags);
					deoHandler.deoTrack(eventName, undefined, userAttrs, metricsTags);
				}
			}
		};

		window.optimizely.push(optimizelyListener);
	})();
}
