import optLssActivation from "./opt-activation.js";
import deoLssActivation from "./deo-activation.js";
try {
  optLssActivation();
} catch (e) {
  console.error('Optimizely Legacy Server Side Integration Error', e);
}
try {
  deoLssActivation();
} catch (e) {
  console.error('DEO Server Side Integration Error', e);
}
