import {deoBatch} from "@bestbuy/deo-client-sdk";

export default function deoLssActivation() {
	(function() {
		let deoConfigfile = window.deoMetaData?.exp?.data ? window.deoMetaData.exp.data : {};
		let userAttributes = window.deoMetaData?.exp?.attrs ? window.deoMetaData.exp.attrs : {};

		const deoExperimentDiv = 'data-exp-key';
		let deoActiveTests = document.querySelectorAll('[' + deoExperimentDiv + ']');

		if (deoActiveTests.length > 0 && deoConfigfile?.exp && Object.keys(
						deoConfigfile?.exp).length > 0) {
			let commonExperiments = Object.keys(deoConfigfile?.exp).filter(
							exp => Array.from(deoActiveTests,
											activeTest => activeTest.getAttribute(deoExperimentDiv)).includes(exp));
			if (userAttributes !== {}) {
				let attributesArray = Object.entries(userAttributes).map(([key, value]) => ({[key]:value}));
				if (commonExperiments.length > 0) {
					deoBatch(commonExperiments, attributesArray);
				}
			}
		}
	})();
}
